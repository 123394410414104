import {Component, ElementRef, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import {BrowserStorageService} from "../browser-storage.service";

@Component({
  selector: 'app-arkroster',
  templateUrl: './arkroster.component.html',
  styleUrls: ['./arkroster.component.scss']
})
export class ArkrosterComponent implements OnInit {

  @ViewChild('rosterCanvas')
  rosterCanvas: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  public defaultTeams: {} = {
    'red': [
      {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
      {'nr': '11', 'name': ''},
      {'nr': '12', 'name': ''},
      {'nr': '13', 'name': ''},
      {'nr': '14', 'name': ''},
      {'nr': '15', 'name': ''},
    ],
    'blue': [
      {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
      {'nr': '11', 'name': ''},
      {'nr': '12', 'name': ''},
      {'nr': '13', 'name': ''},
      {'nr': '14', 'name': ''},
      {'nr': '15', 'name': ''},
    ],
    'green': [ {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
      {'nr': '11', 'name': ''},
      {'nr': '12', 'name': ''},
      {'nr': '13', 'name': ''},
      {'nr': '14', 'name': ''},
      {'nr': '15', 'name': ''},
    ],
    'reserves1': [
      {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
    ],
    'reserves2': [
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
    ]
  };

  public teams: {} = {
    'red': [
      {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
      {'nr': '11', 'name': ''},
      {'nr': '12', 'name': ''},
      {'nr': '13', 'name': ''},
      {'nr': '14', 'name': ''},
      {'nr': '15', 'name': ''},
    ],
    'blue': [
      {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
      {'nr': '11', 'name': ''},
      {'nr': '12', 'name': ''},
      {'nr': '13', 'name': ''},
      {'nr': '14', 'name': ''},
      {'nr': '15', 'name': ''},
    ],
    'green': [ {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
      {'nr': '11', 'name': ''},
      {'nr': '12', 'name': ''},
      {'nr': '13', 'name': ''},
      {'nr': '14', 'name': ''},
      {'nr': '15', 'name': ''},
    ],
    'reserves1': [
      {'nr': '1', 'name': ''},
      {'nr': '2', 'name': ''},
      {'nr': '3', 'name': ''},
      {'nr': '4', 'name': ''},
      {'nr': '5', 'name': ''},
    ],
    'reserves2': [
      {'nr': '6', 'name': ''},
      {'nr': '7', 'name': ''},
      {'nr': '8', 'name': ''},
      {'nr': '9', 'name': ''},
      {'nr': '10', 'name': ''},
    ]
  };

  public teamName: string = '';

  constructor(
    private localStorageService: BrowserStorageService
  ) {
    this.teams = JSON.parse(JSON.stringify(this.defaultTeams));
  }

  ngOnInit(): void {
    this.getStorage();
  }

  clearData() {
    this.teams = JSON.parse(JSON.stringify(this.defaultTeams));
  }

  getStorage() {
    this.teamName = this.localStorageService.get('teamName');

    let teams = this.localStorageService.get('teams');

    if (teams != null ) {
      this.teams = JSON.parse(teams);
    }
  }

  setStorage() {
    this.localStorageService.set('teamName', this.teamName);
    this.localStorageService.set('teams', JSON.stringify(this.teams));
  }

  ngAfterViewInit(): void {
    this.context = this.rosterCanvas.nativeElement.getContext('2d');
    this.addImage(false);
  }

  save() {
    this.context.clearRect(0, 0, this.rosterCanvas.nativeElement.width, this.rosterCanvas.nativeElement.height);
    this.addImage(true);
    this.setStorage();
  }

  addImage(hasCallback) {
    let context = this.context;

    let self = this;

    this.rosterCanvas.nativeElement.width = 1920;              // actual size given with integer values
    this.rosterCanvas.nativeElement.height = 1080;

    // Draw Image function
    var img = new Image();
    img.src = 'assets/rok-roster.png';
    img.onload = function () {
      context.drawImage(img, 0, 0);
      if (hasCallback) {
        self.draw.bind(self)();
      }
    };
  }

  draw() {
    this.context.font = "150px Roboto";
    this.context.fillStyle = "#000000";
    this.context.textAlign = "right";
    this.context.fillText(this.teamName, 1870, 420);
    this.drawReserves();
    this.drawTeam1();
    this.drawTeam2();
    this.drawTeam3();
  }

  drawTeam1() {
    this.context.font = "bold 20px Roboto";
    this.context.fillStyle = "#000000";
    this.context.textAlign = "left";

    let y = 640;

    let counter = 0;

    this.teams['red'].forEach((reserve, index) => {

      if (reserve.name !== '') {
        counter++;
        this.context.fillText(counter + '. ' + reserve['name'], 960, y);
        y += 30;
      }
    });

  }

  drawTeam2() {
    this.context.font = "bold 20px Roboto";
    this.context.fillStyle = "#000000";
    this.context.textAlign = "left";

    let y = 640;

    let counter = 0;

    this.teams['green'].forEach((reserve, index) => {

      if (reserve.name !== '') {
        counter++;
        this.context.fillText(counter + '. ' + reserve['name'], 1285, y);
        y += 30;
      }
    });

  }

  drawTeam3() {
    this.context.font = "bold 20px Roboto";
    this.context.fillStyle = "#000000";
    this.context.textAlign = "left";

    let y = 640;

    let counter = 0;

    this.teams['blue'].forEach((reserve, index) => {

      if (reserve.name !== '') {
        counter++;
        this.context.fillText(counter + '. ' + reserve['name'], 1610, y);
        y += 30;
      }
    });

  }

  drawReserves() {
    this.context.font = "30px Roboto";
    this.context.fillStyle = "#000000";
    this.context.textAlign = "left";

    let y = 870;

    this.teams['reserves1'].forEach((reserve, index) => {

      if (reserve.name !== '') {
        this.context.fillText(reserve['nr'] + '. ' + reserve['name'], 35, y);
        y += 40;
      }
    });

    y = 870;

    this.teams['reserves2'].forEach((reserve, index) => {

      if (reserve.name !== '') {
        this.context.fillText(reserve['nr'] + '. ' + reserve['name'], 450, y);
        y += 40;
      }
    });

  }


  addTextToImage(imagePath, text) {
    let context = this.context;

    this.rosterCanvas.nativeElement.width = 1920;              // actual size given with integer values
    this.rosterCanvas.nativeElement.height = 1080;

    // Draw Image function
    var img = new Image();
    img.src = imagePath;
    img.onload = function () {
      context.drawImage(img, 0, 0);
      context.lineWidth = 1;
      context.fillStyle = "#CC00FF";
      //context.lineStyle = "#ffff00";
      context.font = "18px sans-serif";
      context.fillText(text, 50, 50);
    };
  }


}
