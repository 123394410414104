<div class="content-wrapper">

  <div class="container">

    <div class="row">

      <div class="col-md-12">
        <button type="button" class="btn btn-primary" (click)="saveType('single')">1 background color</button>
        <button type="button" class="btn btn-primary" (click)="saveType('double')">2 background colors</button>
        <button type="button" class="btn btn-primary" (click)="saveType('gradientV')">Gradient vertical</button>
        <button type="button" class="btn btn-primary" (click)="saveType('gradientH')">Gradient horizontal</button>
        <button type="button" class="btn btn-primary" (click)="saveType('gradientD')">Gradient diagonal</button>
      </div>

      <div class="col-md-4">

        <div class="card flex-grow-1">
          <div class="card-body">
            <div class="card-title">
              background color
          </div>
            <color-sketch [color]="stateFront"  (onChangeComplete)="changeFront($event)"></color-sketch>
            <div class="card-title">
              background color 2 (Only with 2 colors or gradient)
            </div>
            <color-sketch [color]="stateFront2"  (onChangeComplete)="changeFront2($event)"></color-sketch>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card flex-grow-1">
          <div class="card-body">
            <div class="card-title">
              Ears and eyes color
            </div>
            <color-sketch [color]="stateBack"  (onChangeComplete)="changeBack($event)"></color-sketch>
            <div class="card-title">
              Detail color 2 (Only with 2 background colors)
            </div>
            <color-sketch [color]="stateBack2"  (onChangeComplete)="changeBack2($event)"></color-sketch>
          </div>
        </div>
      </div>


      <div class="col-md-4">

        <p>Right click the image or long press to save.</p>
        <div class="canvas-container">
          <canvas id="canvas" class="canvas" #iconCanvas></canvas>
          <div id="img-id">
        </div>

        </div>
      </div>
    </div>


  </div>
</div>
