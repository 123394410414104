import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArkrosterComponent} from "./arkroster/arkroster.component";
import {IconComponent} from "./icon/icon.component";

const routes: Routes = [
  { path: 'ark', component: ArkrosterComponent },
  { path: 'icon', component: IconComponent },
  { path: '',   redirectTo: '/ark', pathMatch: 'full' }, // redirect to `ark`
  // { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
