<div class="content-wrapper">


<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">ARK Roster generator 1.0</h4>
          <div class="card-text">
            <p>
              This little tool generates an image for your ARK of Osiris teams based on the information that you provide. You can copy the image on the bottom of this page.
            </p>
            <p>
              <b>Roadmap:</b>
            </p>
              <ul>
            <li>Selectable colors for teams.</li>
                <li>Option to only have 2 teams. You can leave green empty now if needed.</li>
                <li>Editable mail generator to create a nice mail with markup and with the given names and settings.</li>
                <li>Export and Import functionality to easily share settings with each other</li>
              </ul>
            <div class="form-group">
              <label for="teamInput">Team name</label>
              <input [(ngModel)]="teamName" type="text" id="teamInput" class="form-control" aria-describedby="teamInfo">
              <small id="teamInfo" class="form-text text-muted">Can be empty.</small>
              <p>Fill in all your ARK of Osiris members to generate a clean image overview. Your input is saved localy in your browser to allow you to re-use it again.</p>
              <button type="button" class="btn btn-danger" (click)="clearData()">Clear all names</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Team Red Bottom</h4>
          <div class="card-text">
            <div class="form-group input-group" *ngFor="let member of teams['red']">
              <div class="input-group-prepend">
                <span class="input-group-text" >{{ member.nr }}</span>
              </div>
              <input [(ngModel)]="member.name" type="text" class="form-control"  aria-describedby="teamInfo">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Team Green Middle</h4>
          <div class="card-text">
            <div class="form-group input-group" *ngFor="let member of teams['green']">
              <div class="input-group-prepend">
                <span class="input-group-text" >{{ member.nr }}</span>
              </div>
              <input [(ngModel)]="member.name" type="text" class="form-control"  aria-describedby="teamInfo">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Team Blue Top</h4>
          <div class="card-text">
            <div class="form-group input-group" *ngFor="let member of teams['blue']">
              <div class="input-group-prepend">
                <span class="input-group-text" >{{ member.nr }}</span>
              </div>
              <input [(ngModel)]="member.name" type="text" class="form-control"  aria-describedby="teamInfo">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Rreserves</h4>
          <div class="card-text">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group input-group" *ngFor="let reserve of teams['reserves1']">
                  <div class="input-group-prepend">
                    <span class="input-group-text" >{{ reserve.nr }}</span>
                  </div>
                  <input [(ngModel)]="reserve.name" type="text" class="form-control"  aria-describedby="teamInfo">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group input-group" *ngFor="let reserve of teams['reserves2']">
                  <div class="input-group-prepend">
                    <span class="input-group-text" >{{ reserve.nr }}</span>
                  </div>
                  <input [(ngModel)]="reserve.name" type="text" class="form-control"  aria-describedby="teamInfo">
                </div>
              </div>
            </div>

            <button type="button" class="btn btn-primary margin-bottom-10" (click)="save()">Apply all changes</button>
            <p>Save the image by right clicking it with a mouse or long pressing it on your smartphone.</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12">
      <div class="canvas-container ratio-16-9">
        <canvas class="canvas" #rosterCanvas></canvas>
      </div>
    </div>
  </div>


</div>
</div>
