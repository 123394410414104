import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @ViewChild('iconCanvas')
  iconCanvas: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  stateFront: string = "#000000" ;
  stateFront2: string = "#ffffff" ;
  stateBack: string = "#ffffff" ;
  stateBack2: string = "#000000" ;
  fillType: string = "single" ;

  constructor() { }

  ngOnInit(): void {
  }


  changeFront(event) {
    this.stateFront = event['color']['hex'];
    this.save();
  }

  changeBack(event) {
    this.stateBack = event['color']['hex'];
    this.save();
  }

  changeFront2(event) {
    this.stateFront2 = event['color']['hex'];
    this.save();
  }

  changeBack2(event) {
    this.stateBack2 = event['color']['hex'];
    this.save();
  }

  save() {
    this.context.clearRect(0, 0, this.iconCanvas.nativeElement.width, this.iconCanvas.nativeElement.height);
    this.addImage(true);
  }

  saveType(type) {
    this.fillType = type;
    this.save();
  }

  saveToFile() {
    var image = new Image();
    image.id = "pic";
    image.setAttribute("style", "max-width: 100%");
    image.src = this.iconCanvas.nativeElement.toDataURL();
    document.getElementById('img-id').innerHTML = "";
    document.getElementById('img-id').appendChild(image);
  }

  ngAfterViewInit(): void {
    this.context = this.iconCanvas.nativeElement.getContext('2d');
    this.addImage(false);
  }

  addImage(hasCallback) {
    let context = this.context;

    let self = this;

    this.iconCanvas.nativeElement.width = 1400;              // actual size given with integer values
    this.iconCanvas.nativeElement.height = 1400;

    // Draw Image function
    var img = new Image();
    img.src = 'assets/bunny-color.png';
    img.onload = function () {

      context.drawImage(img, 0, 0);

      context.globalCompositeOperation = "source-atop";

      if (self.fillType == "single") {
        context.fillStyle = self.stateFront;
        context.fillRect(0, 0, 1400, 1400);
      } else {
        if (self.fillType == "double") {
          context.fillStyle = self.stateFront;
          context.fillRect(0, 0, 685, 1400);

          context.fillStyle = self.stateFront2;
          context.fillRect(685, 0, 715, 1400);
        } else {
          var grd = null;

          if(self.fillType == "gradientV") {
            grd = context.createLinearGradient(0, 0, 0, 1400);
          } else {
            if (self.fillType == "gradientH") {
              grd = context.createLinearGradient(0, 0, 1400, 0);
            }else {
              grd = context.createLinearGradient(0, 0, 1400, 1400);
            }
          }

          grd.addColorStop(0, self.stateFront);
          grd.addColorStop(1, self.stateFront2);

          context.fillStyle = grd;
          context.fillRect(0, 0, 1400, 1400);
        }
      }



      // Get image data for part of the canvas (to see effects)
      var newimg = context.getImageData( 0, 0, 1400, 1400 ),
        imgData = newimg.data;

      var colors1 = self.hexToRGB(self.stateBack);
      var colors2 = self.hexToRGB(self.stateBack2);

      if(self.fillType !== "double") {
        colors2 = colors1;
      }


      var colors = colors1;
      var state = 1;

    // Loops through bytes and change pixel to white if alpha is not zero.
      for ( var i = 0; i < imgData.length; i += 4 ) {

        if ( i!= 0 && (i % (700*4) == 0)) {
          if(state == 1) {
            colors = colors2;
            state = 2;
          }
          else {
            colors = colors1;
            state = 1;
          }
        }

        if ( imgData[i + 3] === 0 ) {
          imgData[i] = colors[0];
          imgData[i + 1] = colors[1];
          imgData[i + 2] = colors[2];
          imgData[i + 3] = 255;
        }
      }

      context.putImageData( newimg, 0, 0 );

      self.saveToFile();
    };
  }

  hexToRGB(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }

}
